import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
  } from '@angular/common/http';
  import { Observable, throwError } from 'rxjs';

  import { catchError } from 'rxjs/operators';
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
  })
  export class HttpInterceptorService {

    constructor(private router: Router,
                public authSvc: AuthService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // Clonar la solicitud para modificarla si es necesario
      const modifiedReq = req.clone();
  
      // Manejar la solicitud HTTP y capturar errores
      return next.handle(modifiedReq).pipe(
        catchError((error: HttpErrorResponse) => {
          // Verificar si el error contiene un mensaje específico
          if (error.error && error.error.msg === 'Invalid ID token') {
            this.goLogin()
          }
          console.log(error)
          return throwError(error);
        })
      );
    }


    async goLogin(){
      try {
        await this.authSvc.logout();
        sessionStorage.removeItem('currentRol');
        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('allCompanies');
        sessionStorage.removeItem('idToken');
        sessionStorage.removeItem('currentCompany');
        //alert ('')
        location.reload();       
        alert('Sesión expirada')
        this.router.navigate(['/login']);
        
      } catch (error) {
        console.log(error);
      }
 
      
    }
  }
  