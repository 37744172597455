

import { Component, Inject, OnInit, Pipe, PipeTransform  } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
import { Annotation, Model } from 'src/app/core/models';
import { Company } from 'src/app/core/models/company.model';
import { FileItem } from 'src/app/core/models/file-item';

import { StorageService } from 'src/app/core/services/firebase/storage.service';
import { ModelService } from '../../modules/model/infrastructure';
import { ObjStatus } from 'src/app/core/models/common.model';
declare const Sketchfab: any;
export interface DialogData {
    Name:string,
    code: {
      EmbedCode: string
      Id: string
      Link:string
    },
    Autor:string,
    model?:Model
}

@Component({
  selector: 'app-viewer-ske',
  templateUrl: './viewer-ske.component.html',
  styleUrls: ['./viewer-ske.component.scss']
})
export class ViewerSkeComponent implements OnInit {

  public linksrc:string = ""
  public annotations:Array<any> = []
  public editAnnotation:boolean = false
  public openMenu:boolean = false
  public load:boolean = false
  currentCompany = JSON.parse(sessionStorage.getItem('currentCompany') || '{}') as Company;
  file?: FileItem;

  newAnnotation:Annotation = {
    Uid: '',
    Title: '',
    Content: {
      Image: '',
      Video: '',
      Text: ''
    }
  }


  detailForm = new FormGroup({
    Title: new FormControl(''),
    Text:new FormControl(''),
  })
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              private sanitizer: DomSanitizer,
              private storageSvc: StorageService,
              private modelService: ModelService,
               ) { }

  ngOnInit(): void {

    console.log(this.data.code.EmbedCode)
    console.log(this.data)

   this.getMaterialList(this.data.code.Id)
  }

  transform() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.linksrc);
  }
  getMaterialList(uid: string) {
    var iframe = document.getElementById('api-frame');
    var version = '1.12.1';
    var client = new Sketchfab(version, iframe);
    console.log(client)
    
    client.init(uid, {
        transparent: 1,
        ui_infos: 0,
        ui_controls: 0,
        ui_watermark: 0,
        camera:0,
        ui_stop:0,
        success: function onSuccess(api: any) {
          api.start();
          api.addEventListener('viewerready', function () {
            api.getAnnotationList((err: any, annotations: any) => {
              if (!err) {
                sessionStorage.setItem('annotationsViewerModels',JSON.stringify(annotations));
                document.getElementById(`annotationsViewer`)?.click();
                annotations.forEach((annotation: any, index: number) => {
                  api.hideAnnotation(index, (err: any, index: any) => {
                    if (!err) {
                      //window.console.log('annotations ok', index + 1);
                    }
                  });
                });
              }
            });

            api.getMaterialList((err: any, surfaces: any) => {
                   
            
            })


          })
        }
      })
  }
 

  

  readAnnotations(){
   console.log(this.data.model)
   this.annotations = JSON.parse(sessionStorage.getItem('annotationsViewerModels')!)
   this.annotations && this.annotations.length ? this.editAnnotation = true : null
   
   this.data.model?.DetailAnnotations != undefined ? this.modifyDataSketchfab(this.annotations) : null

  }
  modifyDataSketchfab(annotations:Array<any>){
    this.data.model?.DetailAnnotations?.forEach(detail => {

      let annotation = annotations.find( data => data.uid == detail.Uid)
      annotation ? (annotation.name = detail.Title) : null

    })
    
  }
  loadForm(annotation:any){
    this.data.model?.DetailAnnotations?.length ? this.updateForm(annotation) : this.newFormAnnotation(annotation)
  }
  updateForm(annotation:any){

    let detail = this.data.model?.DetailAnnotations?.find(detail => detail.Uid === annotation.uid)

    this.newAnnotation = {
      Uid: annotation.uid,
      Title: annotation.name,
      Content: {
        Image: detail?.Content.Image || '',
        Video: detail?.Content.Video || '',
        Text: detail?.Content.Text || ''
      }
    }

    this.detailForm.controls.Title.setValue(this.newAnnotation.Title)
    this.detailForm.controls.Text.setValue(this.newAnnotation.Content.Text)

    this.openMenu = false
  }
  newFormAnnotation(annotation:any){
    console.log(annotation)
    this.newAnnotation = {
      Uid: annotation.uid,
      Title: annotation.name,
      Content: {
        Image: '',
        Video: '',
        Text: ''
      }
    }

    this.detailForm.controls.Title.setValue(this.newAnnotation.Title)
    this.detailForm.controls.Text.setValue(this.newAnnotation.Content.Text)

    this.openMenu = false
  }
  getFileRef(url: string) {
    if (url) {
      let splitQ = url.split('?');

      let fileURI = splitQ[0].split('/');
      console.log(fileURI)
      let child = fileURI[7].split('%2F');
      console.log(child)
      let fileRef = child[6];

      return fileRef;
    } else return null;
  }
  async upImgStorage(file:any, path:string, ref:string | null){
    return new Promise((resolve, reject) => {
    this.storageSvc.modifyFile(file, path, ref).then(async (res) => {
      let url = (await this.file?.downloadURL?.toPromise().then()) || '';
      resolve(url);
     });
    })
  }
  async onUploadImg(e: any) {
    this.load = true

    let path = `Detail/company/${this.currentCompany.Uid}/annotation/${this.newAnnotation.Uid}/files`;

    let ref = this.newAnnotation.Content.Image ? this.getFileRef(this.newAnnotation.Content.Image): this.newAnnotation.Content.Video ? this.getFileRef(this.newAnnotation.Content.Video) :null ; //nuevo

    this.file = new FileItem(e.target.files[0]);

    this.upImgStorage(this.file,path,ref).then((url:any) => {
                                          let type = this.type(url)
                                          this.saveImageModel(type,url)
                                        }
    )

  }
  saveImageModel(type:string,url:string){
    type == 'imagen' ? (this.newAnnotation.Content.Image = url , this.newAnnotation.Content.Video = '') : null
    type == 'video' ? (this.newAnnotation.Content.Image = '' , this.newAnnotation.Content.Video = url) : null

    this.save()
  }
  type(url:string){
    let type
    let response:string = ''
    type = url.split('.jpeg?')
    type.length > 1 ? response = 'imagen' : null

    type = url.split('.png?')
    type.length > 1 ? response = 'imagen' : null

    type = url.split('.mp4?')
    type.length > 1 ? response = 'video' : null

    return response
  }

  async save(){
    
    this.detailForm.controls.Title.value ? this.newAnnotation.Title = this.detailForm.controls.Title.value  : null
    this.detailForm.controls.Text.value ? this.newAnnotation.Content.Text = this.detailForm.controls.Text.value  : null

    let auxModel:any = this.data.model

    auxModel.DetailAnnotations == undefined ? (auxModel.DetailAnnotations = [this.newAnnotation] , this.data.model = auxModel) : this.updateModel()
    
    if(this.data.model != undefined && this.data.model.AddedDate._seconds){
      
      this.data.model.Note ? this.data.model.Note.Date = this.data.model.Note.Date  ? new Date(this.data.model.Note.Date._seconds * 1000).toLocaleString('en-US', { timeZone: 'GMT' }) : ''  : null
      
      this.data.model.AddedDate =   new Date(this.data.model.AddedDate._seconds * 1000).toLocaleString('en-US', { timeZone: 'GMT' })


      this.data.model.Status.forEach(element => {
        element.DateStart = new Date(element.DateStart._seconds * 1000).toLocaleString('en-US',{ timeZone: 'GMT' })
        element.DateEnd = element.DateEnd ? new Date(element.DateStart._seconds * 1000).toLocaleString('en-US',{ timeZone: 'GMT' }): null
                                                });
          
      this.data.model.Edits.forEach(element => {
        element.Date = new Date(element.Date._seconds * 1000).toLocaleString('en-US', {timeZone: 'GMT',})
      })

    }

    console.log(this.data.model,'Actualizar',this.newAnnotation)
    try {
      this.data.model ? ( await this.modelService.updateModel(this.data.model).toPromise() , this.load = false ) : null
    } catch (error) {
      console.log(error)
      this.load = false 
    }

    
  }
  updateModel(){

    let index = this.data.model?.DetailAnnotations?.findIndex(dettail => dettail.Uid === this.newAnnotation.Uid)
    
    index != -1 && index  || index == 0 ? this.data.model?.DetailAnnotations?.length ? this.data.model.DetailAnnotations[index] = this.newAnnotation : null : null

    index == -1 ? this.data.model?.DetailAnnotations?.length ? this.data.model.DetailAnnotations.push(this.newAnnotation) : null : null

  }

  

}
