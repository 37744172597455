import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { apiKeys, apiUrl } from '../../data/appSettings.data';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  // baseURL = environment.apiURL;
  NewBaseUrl = apiUrl;
  keys = apiKeys;

  constructor(private http: HttpClient) {}

  getUserById(userId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-api-key': environment.keys,
      })
    };

    //console.log("LOGEO REPETIDO?");

    let params = new HttpParams().set('userId', userId);
    return this.http.get(this.NewBaseUrl + 'user/read/id', {params, ...httpOptions});
  }
}
