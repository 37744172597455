import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from 'src/app/core/models/user.model';
import { environment } from 'src/environments/environment';
import { apiKeys, apiUrl } from '../../data/appSettings.data';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private readonly newBaseUrl = apiUrl;
  private readonly keys = apiKeys;

  private httpOptions = {
    headers: new HttpHeaders({
      'x-api-key': environment.keys,
    })
  };

  constructor(private http: HttpClient) { }

  private setHttpOptionsWithAuthAndCompany(): void {
    const currentCompany = sessionStorage.getItem('currentCompany');
    const Uid = currentCompany ? JSON.parse(currentCompany).Uid : '';

    this.httpOptions.headers = this.httpOptions.headers.set('AuthorizationV2', `Bearer ${sessionStorage.getItem('idToken')}`);
    this.httpOptions.headers = this.httpOptions.headers.set('currentCompany', `${Uid}`);
  }

  createRole(role: any): Observable<any> {
    this.setHttpOptionsWithAuthAndCompany();
    return this.http.post(`${this.newBaseUrl}user/roles/create`, role, this.httpOptions);
  }

  updateRole(role: any): Observable<any> {
    this.setHttpOptionsWithAuthAndCompany();
    return this.http.put(`${this.newBaseUrl}user/roles/update`, role, this.httpOptions);
  }

  getRoles(): Observable<any> {
    return this.http.get(`${this.newBaseUrl}user/roles/read`, this.httpOptions);
  }

  deleteRole(roleId: string): Observable<any> {
    this.setHttpOptionsWithAuthAndCompany();
    return this.http.delete(`${this.newBaseUrl}user/roles/delete?uid=${roleId}`, this.httpOptions);
  }
}
