import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface imgPop{

  Name:string,
  render:string,
  Autor:string,
  Photo:string
  
}

@Component({
  selector: 'app-readimg',
  templateUrl: './readimg.component.html',
  styleUrls: ['./readimg.component.scss']
})
export class ReadimgComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public imgInf: imgPop) { }

  ngOnInit(): void {
  }

}
