<button class="btn btn1 popup-close" mat-dialog-close>X</button>

<h1 style="font-weight: bold">{{ inf.message }}</h1>

<h2>{{ inf.parafo }}</h2>

<br />

<Button class="btn btn1 add" *ngIf="inf.button == true" (click)="onLink()">{{
  inf.message_button
}}</Button>
