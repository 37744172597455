// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyD5-StOeyK6qPYcziPGBcZMhabJavNeyQg',
    authDomain: 'tangible-universal-wl.firebaseapp.com',
    projectId: 'tangible-universal-wl',
    storageBucket: 'tangible-universal-wl.appspot.com',
    messagingSenderId: '164197835931',
    appId: '1:164197835931:web:c48f42f029d4727578523e',
    measurementId: 'G-S2JBCD0HKN',
  },
  apiURL: 'https://universal-backend.tangibleimmersive.co/',
  NewapiURL: 'https://universal-backend.tangibleimmersive.co/v2/',//'https://us-central1-tangible-universal-dev-refact.cloudfunctions.net/',
  keys:'461K#U9!D$in',
  companies: {
    defaultCompany: {
      Uid: '9IXzkOzgQJ3koKWAOTO6',
      Name: 'Tangible Inmersivo',
    },
    AlfaCompany: {
      Uid: '9WK9YFdjwUhfdzTtwOuW',
      Name: 'Alfa',
    },
    tugoCompany: {
      Uid: '7IKetdiy1pBDCdQTZr2a',
      Name: 'Tugó',
    },
    ajusteGenerales: {
      Uid: 'vZvQjTTlJtbibg13SUek',
      Name: 'Ajustes generales',
    },
    appArTugo: {
      Uid: 'B10cNaEGbrMPdNVxZNri',
      Name: 'App Ar Tugo',
    },
    testTendecia: {
      Uid: 'zZV7y1js8o2k4U4RxaC8',
      Name: 'Test Tendecia',
    },
    devTest: {
      Uid: '35JgsdG61w0x0z4lBaxQ',
      Name: 'Test Tendencias',
    },
    testSettings:{
      Uid: 'GONmtStQuS4wtxDWAXoG',
      Name: 'Ajustes',
    },
    defaultRole: {
      Uid: '20J78xexi9q03eQXf5mF',
      Name: 'Basic',
    },
    superAdminRole: {
      Uid: 'gqTjlEGmnGrxR9Ef1OFs',
      Name: 'Super Admin',
    },
    RepresentanteRole: {
      Uid: 'ajBVKOICnkBvPJcNtbQm',
      Name: 'Representante',
    },
    adminRole: {
      Uid: 'hXd2GZ5h5Z5WK5dmBZwS',
      Name: 'Admin',
    },
    t3DArtistRole: {
      Uid: 'UIKKZLg4MHyAV7WOjBPf',
      Name: '3D Artist',
    },
    t3DSuperRole: {
      Uid: 'OG0XaKExmyGnMT7Rn7EY',
      Name: '3D Super',
    },
    VitrinasItinerantes: {
      Uid: 'HxFlsdLo12WbHUwDN0Yn',
      Name: 'Vitrinas Itinerantes',
    },
    Visualizador3D: {
      Uid: '1LddJUlKLDicYuLOaP9p',
      Name: 'Visualizador 3D',
    },
    Digital_Kiosk: {
      Uid: 'YQkb31040m4KREFK65As',
      Name: 'Digital Kiosk',
    },

    RealTime:{
      Uid:'EoqMz1LoRG4m85I1ZFsf',
      Name:'Diseño Real Time - VR'
    },
    Visualizado3D:{
      Name:'Visualizado 3D',
      Uid:'JcLghbhsYHxIPg0MU3Yt'
    }
    ,
    VisualizadorUniversal:{
      Name:'Visualizador Universal',
      Uid:'PxKD34545Yq0jrJOHDNm'
    }
    ,
    midjourny:{
      Name:'midjourny',
      Uid:'QoPIbVqbXgujSDwdj7Ee'
    },
    pdfView:{
      Name:'pdf interactivo ',
      Uid:'4DNBRhNWVSrnRRK9WKFt'
    },
    sectionInspirate :{
      Name:'inspirate',
      Uid:'e2jXdYQCQtXZOL2BXo6d'
    },
  },

  sectionKios:[{
    Name:'Home',
    Uid:'DqtWVotu8D8blAtVmWW5'
  },
  {
    Name:'Lanzamientos',
    Uid:'go2ts8nEW2JGQo5nbxpU'
  },
  {
    Name:'Inspirate',
    Uid:'HH9A4efXaHlknV34bW2m'
  },
  {
    Name:'Landing 1',
    Uid:'R92jydeOFa5zp3RjAEyX'
  },
  {
    Name:'Landing 2',
    Uid:'waj5fVHt2bUnod4lEyXM'
  },
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.



