import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit{
  title = 'universal-admin';

  

  constructor(private translate: TranslateService,private http: HttpClient) {
    translate.setDefaultLang("es")
  }
  ngOnInit(): void {
    console.log('1')
  }
  
}
