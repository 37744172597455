import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import { environment } from 'src/environments/environment';
import { apiKeys, apiUrl } from '../../data/appSettings.data';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // baseURL = environment.apiURL;
  NewBaseUrl = apiUrl;
  keys = apiKeys;

  httpOptions = {
    headers: new HttpHeaders({
      'x-api-key': environment.keys,
    })
  };

  constructor(private http: HttpClient) {}

  createUser(user: User): Observable<any> {
    return this.http.post(this.NewBaseUrl + 'user/create', user, this.httpOptions);
  }

  updateUser(user: User): Observable<any> {
    return this.http.put(this.NewBaseUrl + 'user/update', user, this.httpOptions);
  }

  // deleteUser(user: User): Observable<any> {
  //   return this.http.delete(this.NewBaseUrl + `/${user.Uid}`, this.httpOptions);
  // }

  // getUserByEmail(email: string): Observable<any> {
  //   return this.http.get(this.NewBaseUrl + `/email?email=${email}`, this.httpOptions);
  // }

  getUsersByCompanyPage(companyName: string, page?: number): Observable<any> {
    return this.http.get(
      this.NewBaseUrl + `user/read?companyName=${companyName}&page=${page}`, this.httpOptions
    );
  }

  searchByCompany(
    search: string,
    companyName: string,
    page?: number
  ): Observable<any> {
    return this.http.get(
      this.NewBaseUrl +
        `user/read/search?companyName=${companyName}&search=${search}&page=${page}`, this.httpOptions
    );
  }
}
