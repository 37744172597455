<h1 mat-dialog-title>Selecciona una compañía</h1>

<mat-spinner [diameter]="40" class="flex-grow-1 ms-5" *ngIf="loading"></mat-spinner>

<div mat-dialog-content>
    <mat-selection-list #company [multiple]="false" (selectionChange)="onSelect($event, false)">
        <mat-list-option *ngFor="let company of companies" [value]="company.Uid">
            {{company.Name}}
        </mat-list-option>
    </mat-selection-list>
</div>
{{text}}
