import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { informacionPopups } from 'src/app/core/models/user.model';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public inf: informacionPopups,
    private router: Router,
    private dialogRef: MatDialogRef<AlertComponent>
  ) {}

  onLink() {
    if (this.inf.link) {
      this.router.navigate([this.inf.link]);
    } else {
      console.log('no existe el link');
    }
    this.dialogRef.close();
  }
}
