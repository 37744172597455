import { Inject, Injectable } from "@angular/core";
import { FileItem } from "src/app/core/models/file-item";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { filter, finalize } from "rxjs/operators";
import { Observable } from "rxjs";


@Injectable({
  providedIn: "root",
})
export class StorageService {
  public CATEGORIES_IMG_PATH_COMPANY = "company/logo";
  public CATEGORIES_FONT_PATH_COMPANY = "company/font";
  public CATEGORIES_ING_PATH_USER = "user/photo";
  public CATEGORIES_ING_PATH_CATALOGS = "catalogs/Thumbnail";
  public CATEGORIES_ING_PATH_DEVS = "developments/Logo";

  constructor(private storage: AngularFireStorage) {}

  //constructor(@Inject(AngularFireStorage) private storage: AngularFireStorage) {}

  uploadFiles(folderPath: string, Files: FileItem[]) {
    for (const item of Files) {
      item.uploading = true;

      const filePath = this.generateFileName(folderPath, item.name);
      const fileRef = this.storage.ref(filePath); //hubicacion
      const task = this.storage.upload(filePath, item.file); //task uploadFiles

      item.uploadPercent = task.percentageChanges();

      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            item.downloadURL = fileRef.getDownloadURL();
            item.uploading = false;
          })
        )
        .subscribe();
    }
  }

  async modifyFile(item: FileItem, path: string, ref: string | null) {
    await new Promise((resolve) => {
      item.uploading = true;

      let filePath: string;
      if (ref) this.storage.ref(`${path}/${ref}`).delete()
      filePath = this.generateFileName(path, item.name);

      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, item.file);
      item.uploadPercent = task.percentageChanges()!;
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            item.downloadURL = fileRef.getDownloadURL();
            item.uploading = false;
            resolve(true);
          })
        )
        .subscribe();
    });
  }

  deleteFile(path: string, ref: string) {
    this.storage.ref(`${path}/${ref}`).delete()
  }

  generateFileName(folderPath: string, name: string): string {
    return `${folderPath}/${new Date().getTime()}_${name}`;
  }
}
