import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApisService {

  constructor() { }
  procesarDatos(data: any): string {
    console.log(data);
    return 'Datos procesados correctamente';
  }
}
