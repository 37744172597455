<button class="btn btn1 popup-close" mat-dialog-close>X</button>
<div class="w-100 h-100 center-adsolute loading" *ngIf="load">
    <svg class="center-adsolute" height="50px" width="50px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 512 512"  xml:space="preserve">
<style type="text/css">
   .st0{fill:#FFFFFF;}
</style>
<g>
   <path class="st0" d="M256,0c-23.357,0-42.297,18.932-42.297,42.288c0,23.358,18.94,42.288,42.297,42.288
       c23.357,0,42.279-18.93,42.279-42.288C298.279,18.932,279.357,0,256,0z"/>
   <path class="st0" d="M256,427.424c-23.357,0-42.297,18.931-42.297,42.288C213.703,493.07,232.643,512,256,512
       c23.357,0,42.279-18.93,42.279-42.288C298.279,446.355,279.357,427.424,256,427.424z"/>
   <path class="st0" d="M74.974,74.983c-16.52,16.511-16.52,43.286,0,59.806c16.52,16.52,43.287,16.52,59.806,0
       c16.52-16.511,16.52-43.286,0-59.806C118.261,58.463,91.494,58.463,74.974,74.983z"/>
   <path class="st0" d="M377.203,377.211c-16.503,16.52-16.503,43.296,0,59.815c16.519,16.52,43.304,16.52,59.806,0
       c16.52-16.51,16.52-43.295,0-59.815C420.489,360.692,393.722,360.7,377.203,377.211z"/>
   <path class="st0" d="M84.567,256c0.018-23.348-18.922-42.279-42.279-42.279c-23.357-0.009-42.297,18.932-42.279,42.288
       c-0.018,23.348,18.904,42.279,42.279,42.279C65.645,298.288,84.567,279.358,84.567,256z"/>
   <path class="st0" d="M469.712,213.712c-23.357,0-42.279,18.941-42.297,42.288c0,23.358,18.94,42.288,42.297,42.297
       c23.357,0,42.297-18.94,42.279-42.297C512.009,232.652,493.069,213.712,469.712,213.712z"/>
   <path class="st0" d="M74.991,377.22c-16.519,16.511-16.519,43.296,0,59.806c16.503,16.52,43.27,16.52,59.789,0
       c16.52-16.519,16.52-43.295,0-59.815C118.278,360.692,91.511,360.692,74.991,377.22z"/>
   <path class="st0" d="M437.026,134.798c16.52-16.52,16.52-43.304,0-59.824c-16.519-16.511-43.304-16.52-59.823,0
       c-16.52,16.52-16.503,43.295,0,59.815C393.722,151.309,420.507,151.309,437.026,134.798z"/>
</g>
</svg>
</div>
 <div class="body" [ngClass]="{'body-extend': editAnnotation}">
    <div class="model-view">
        <h1 style="font-weight: bold; font-size: 20px;">{{data.Name}}</h1>

        <div style="height: 350px; width: 350px;" class="sketchfab-embed-wrapper">
            <iframe id="api-frame" class="h-100 w-100"></iframe>
        </div>
        <div id="setCameraLookAt" hidden></div>
        <h2 class="mt-3" *ngIf="data.Autor;" style="font-size: 20px;">Artista: {{data.Autor}}</h2>
        <div id="annotationsViewer" (click)="readAnnotations()"></div>
    </div>

    <div class="model-view form" *ngIf="editAnnotation" >
        <div class="w-100 position-relative menu"  >
            <h1 class="center-left" style="font-size: 12px;" (click)="openMenu = !openMenu" >Anotaciones</h1>

            <svg (click)="openMenu = !openMenu" class="center-right" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path d="M5.76217 7.35H11.4379L8.60004 10.1879L5.76217 7.35ZM8.60004 15.85C7.51289 15.85 6.49321 15.6438 5.53947 15.2323C4.58281 14.8195 3.75215 14.26 3.0461 13.5539C2.34006 12.8479 1.78056 12.0172 1.36776 11.0606C0.956215 10.1068 0.750037 9.08714 0.750037 8C0.750037 6.91286 0.956215 5.89317 1.36776 4.93943C1.78056 3.98277 2.34006 3.15211 3.0461 2.44607C3.75215 1.74002 4.58281 1.18053 5.53947 0.767725C6.49321 0.356178 7.51289 0.15 8.60004 0.15C9.68718 0.15 10.7069 0.356178 11.6606 0.767725C12.6173 1.18053 13.4479 1.74002 14.154 2.44607C14.86 3.15211 15.4195 3.98277 15.8323 4.93943C16.2439 5.89317 16.45 6.91286 16.45 8C16.45 9.08714 16.2439 10.1068 15.8323 11.0606C15.4195 12.0172 14.86 12.8479 14.154 13.5539C13.4479 14.26 12.6173 14.8195 11.6606 15.2323C10.7069 15.6438 9.68718 15.85 8.60004 15.85ZM8.60004 14.55C10.4256 14.55 11.9774 13.9148 13.2461 12.6461C14.5148 11.3773 15.15 9.82561 15.15 8C15.15 6.17439 14.5148 4.62265 13.2461 3.35393C11.9774 2.08521 10.4256 1.45 8.60004 1.45C6.77443 1.45 5.22269 2.08521 3.95397 3.35393C2.68525 4.62265 2.05004 6.17439 2.05004 8C2.05004 9.82561 2.68525 11.3773 3.95397 12.6461C5.22269 13.9148 6.77443 14.55 8.60004 14.55Z" fill="white" stroke="black" stroke-width="0.3"/>
            </svg>

            <div class="expand position-absolute w-100" *ngIf="openMenu">
                <div class="item-annotation element-center" *ngFor="let item of annotations" (click)="loadForm(item)">{{item.name}}</div>
            </div>
        </div>

        <div class="w-100 position-relative form-edit">
            <form class="" [formGroup]="detailForm">
                <span class="mt-5" style="font-size: 12px;">Titulo</span>
                <input type="text"  class="w-100 mt-3" formControlName="Title" placeholder="titulo">
                <span class="mt-5">Texto</span>
                <textarea name="texto" class="w-100 mt-3" id="" formControlName="Text"></textarea>
            <!--
                <span class="mt-5">img/video</span>

               <div class="w-100 d-float">
                <div class="box-img-video position-relative">
                    <h5 class="center-adsolute">img</h5>
                </div>
                <div class="box-img-video position-relative">
                    <div class="center-top">
                        <label class="switch">
                            <input type="checkbox" checked>
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
                <div class="box-img-video position-relative">
                    <h5 class="center-adsolute">Video</h5>
                </div>
            
               </div>

            -->

               <span class="mt-5" *ngIf="newAnnotation.Uid">img/Video</span>

               <div class="mt-3 w-100 position-relative img-video"  *ngIf="newAnnotation.Uid">
                    
                    <span class="center-left" style="left: 10px;" >{{newAnnotation.Content.Image || newAnnotation.Content.Video}}</span>
                

                    <div class="upload-button center-right" (click)="imageInput.click()">
                        <div class="position-relative w-100 h-100">
                            
                            
                                <svg class="center-adsolute"  viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 13.8599L10.87 10.8C11.0125 10.6416 11.1868 10.5149 11.3815 10.4282C11.5761 10.3415 11.7869 10.2966 12 10.2966C12.2131 10.2966 12.4239 10.3415 12.6185 10.4282C12.8132 10.5149 12.9875 10.6416 13.13 10.8L16 13.8599" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M3 7.41992L3 17.4199C3 19.6291 4.79086 21.4199 7 21.4199H17C19.2091 21.4199 21 19.6291 21 17.4199V7.41992C21 5.21078 19.2091 3.41992 17 3.41992H7C4.79086 3.41992 3 5.21078 3 7.41992Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                        </div>
                    </div>
               </div>

               

               
               
               <div class="w-100 position-relative mt-5" *ngIf="newAnnotation.Uid">
                    <button class="center-adsolute save" (click)="load = true; save()">Guardar</button>
               </div>

               

            </form>
        </div>
        <!--
            <button class="button-detail" (click)="go()">Ir a Detail</button>
        -->
    </div>
 </div>

 <input type="file" id="image" class="d-none" accept="image/png, image/jpeg, video/mp4" #imageInput (change)="onUploadImg($event)" />