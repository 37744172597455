import { UserService } from 'src/app/core/services/user/user.service';
import { LoginService } from 'src/app/core/services/login/login.service';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Company } from 'src/app/core/models/company.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { Role, User } from 'src/app/core/models/user.model';
import { AlertComponent } from '../Alert-message/alert.component';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { defaultRole } from 'src/app/core/data/appSettings.data';
import { CompanyService } from 'src/app/core/services/company/company.service';
import { RoleService } from 'src/app/core/services/roles/roles.service';
import { MatSelectionListChange } from '@angular/material/list';

export interface AdminAllows {
  Development: {
    DevName: string;
    DevId: string;
  };
  Section: Array<{
    SectionName: string;
    SectionId: string;
    Create: boolean;
    Read: boolean;
    Update: boolean;
    Delete: boolean;
    Review: boolean;
  }>;
}

export interface Section {
  Uid: string;
  estado: string;
  title: string;
  value: string;
}

@Component({
  selector: 'app-check-company',
  templateUrl: './check-company.component.html',
  styleUrls: ['./check-company.component.scss'],
})
export class CheckCompanyComponent implements OnInit {
  companies: Array<Company> = [];
  currentUser: any | null;
  roles: Array<Role> = [];
  RolesUser: Array<any> = [];
  stateSelect: boolean = true;
  UidAjusteGenerales: string = 'vZvQjTTlJtbibg13SUek';
  companyUid?: string;
  loading: boolean = false;
  text: string = '';

  constructor(
    public dialogRef: MatDialogRef<CheckCompanyComponent>,
    private authSvc: AuthService,
    private router: Router,
    private http: HttpClient,
    public dialog: MatDialog,
    public navigationSvc: NavigationService,
    private loginService: LoginService,
    private userService: UserService,
    private companyService: CompanyService,
    private roleService: RoleService,
    private route: Router
  ) {}

  async ngOnInit() {
    this.readUrlGoogleOrFace()
    const allCompanies = await this.companyService.getCompanies().toPromise();
    sessionStorage.setItem('allCompanies', JSON.stringify(allCompanies));
    console.log('companies-> ', allCompanies);
    //this.companies = allCompanies as Array<Company>

    const activeCompanies = allCompanies.filter((element: any)=>element.IsActive === true); //Cambiar a true
    console.log("Active Companies ", activeCompanies);

    this.authSvc.currentUser?.BelongsTo.forEach((e) => {
      const found = activeCompanies.find((c: any) => c.Uid === e.Company.Uid);
      if (found) this.companies.push(found); // companies belonging to the user
    });

    console.log("this.companies--> ", this.companies)

    // auto set company
    if (window.opener && window.opener !== window) {
      this.companyUid = JSON.parse(window.name).companyUid;
      this.onSelect(null, true);
    }
  }
  readUrlGoogleOrFace(){
    try {
      const google = this.route.url.split('Google=');
      if(google[1] == 'true'){
        (<any>window).opener.postMessage(
          {
            user: this.authSvc.currentUser,
            company: this.authSvc.currentCompany,
          },
          '*'
        );
      }
      const facebook = this.route.url.split('Facebook=');
      if(facebook[1] == 'true'){
        (<any>window).opener.postMessage(
          {
            user: this.authSvc.currentUser,
            company: this.authSvc.currentCompany,
          },
          '*'
        );
      }
    } catch (error) {
      console.log('not Mode Url Google or Facebook')
    }
  }

  async onSelect(e: MatSelectionListChange | null, auto: boolean) {
    const selectedCompany = e?.source.selectedOptions.selected[0]?.value as string;
    //window.location.reload();

    this.stateSelect = false;

    if (auto) {
      this.authSvc.currentCompany = this.companies.find(
        (element) => element.Uid === this.companyUid
      ) as Company;
    }
    else {
      console.log("Selector nativo")
      this.authSvc.currentCompany = this.companies.find(
        // (element) => element.Uid === e?.options?.values
        (element) => element.Uid === selectedCompany
      ) as Company;
    }

    sessionStorage.setItem(
      'currentCompany',
      JSON.stringify(this.authSvc.currentCompany)
    );

    //console.log(this.authSvc.currentUser)
    console.log("CLIENT", (<any>window).client_id);
    //  alert((<any>window).client_id)
    const params = new HttpParams()
      .set('userInfo', JSON.stringify(this.authSvc.currentUser))
      .set('companyInfo', JSON.stringify(this.authSvc.currentCompany))
      .set('clientId', (<any>window).client_id); //pruebas
    const unityRequest = this.http
      .get<any>('http://127.0.0.1:82/', { params })
      .toPromise();

    //return

    this.getUser();
    console.log(e);
    console.log(params);

    //Send to dev
    if (window.opener && window.opener !== window) {
      (<any>window).opener.postMessage(
        {
          user: this.authSvc.currentUser,
          company: this.authSvc.currentCompany,
        },
        '*'
      );
      (<any>window).close();
    }

    this.dialogRef.close();
    //this.router.navigate(['/home/general/users']);  //ok
  }
  async getUser() {
    this.stateSelect = true;

    this.authSvc.afAuth.authState.subscribe(
      async (user) => {
        if (user) {
          //const currentUserString = await sessionStorage.getItem("currentUser");
          const currentUserString = await sessionStorage.getItem("user");

          if (currentUserString) {
            this.currentUser = JSON.parse(currentUserString);
            console.log("LEEEME")
            sessionStorage.setItem(
              'currentRol',
              JSON.stringify(this.currentUser)
            );
          }

          if (this.stateSelect == true) {
            this.stateSelect = false;

            this.rolesGet();
          }
        } else {
          console.log('AUTHSTATE USER EMPTY', user);
        }
      },
      (err) => {
        console.log(
          `${err.status} ${err.statusText} (${err.error.message})`,
          'Please try again'
        );
      }
    );

  }

  updateLastConnection() {
    this.currentUser.LastConnection = new Date();
    console.log(this.currentUser);
    try {
      this.userService
        .updateUser(this.currentUser)
        .toPromise()
        .then((r) => console.log(r));
    } catch (e) {
      console.log('Something went wrong', e);
    }
  }

  async rolesGet() {
    try {
      this.roles = (await this.roleService.getRoles().toPromise()) as Array<Role>;
      this.filtarRol();
    } catch (e) {
      console.log('Something went wrong in getting roles: ', e);
    }
  }

  filtarRol() {
    let currentUser: User = JSON.parse(
      sessionStorage.getItem('currentRol') || '{}'
    );
    // console.log(currentUser.BelongsTo);
    // console.log(this.roles);

    let RolesUser = new Array();
    RolesUser = [];

    console.log("FIltrar rol: ", currentUser.BelongsTo);
    console.log("FIltrar rol length: ", currentUser.BelongsTo.length);
    //return
    for (let i = 0; i < currentUser.BelongsTo.length; i++) {
      if (
        currentUser.BelongsTo[i].Company.Uid ===
        this.authSvc.currentCompany?.Uid
      ) {
        currentUser.BelongsTo[i].Roles.filter((rol: Object) => {
          this.roles.filter((rolUser) => {
            if (rolUser.Uid == Object(rol).Uid) {
              RolesUser.push({
                Name: rolUser.Name,
                Priority: rolUser.Priority,
                Uid: rolUser.Uid,
              });
            }
          });
        });
      }
    }

    //Elejimos el rol con la mayor prioridad
    for (let i = 0; i < RolesUser.length; i++) {
      for (let j = i; j < RolesUser.length; j++) {
        if (RolesUser[i].Priority > RolesUser[j].Priority) {
          let menor = RolesUser[j];
          RolesUser[j] = RolesUser[i];
          RolesUser[i] = menor;
        }
      }
    }

    if (RolesUser.length != 0) {
      if (RolesUser[0].Uid != defaultRole.Uid) {
        let rolUsuario: Array<Role> = [];
        this.roles.filter((role) => {
          if (role.Uid == RolesUser[0].Uid) rolUsuario.push(role); //rol con todos los parametros
        });

        let rolesRestricionfill: AdminAllows = rolUsuario[0].AdminAllows.find(
          (e) => e.Development.DevId === this.UidAjusteGenerales
        ) as AdminAllows;

        let cont: number = 0;

        let navigate: any;
        while (!navigate) {
          navigate = this.navigationSvc.menu.options.find(
            (e: any) =>
              e.Uid === rolesRestricionfill.Section[cont].SectionId &&
              e.value != ''
          ) as Section;
          cont++;
        }

        this.dialogRef.close();
        this.router.navigate(['/home/general/' + navigate.value]).then(() => {
          window.location.reload();
        });
      } else {
        this.dialogRef.close();
        this.AlertPopups();
        this.router.navigate(['/login']);
      }
    } else {
      this.dialogRef.close();
      this.AlertPopups();
      this.router.navigate(['/login']);
    }
  }

  AlertPopups() {
    this.dialog.open(AlertComponent, {
      data: {
        message: 'rol:BASICO',
        parafo:
          'Lo sentimos pero no tienes ,permisos nesesarios para continuar, contacta un Administrador para cambiar tu rol',
        link: '',
        message_button: '',
        button: false,
      },
    });
  }
}
