<button class="btn btn1 popup-close" mat-dialog-close>X</button>

<h1 style="font-weight: bold">{{imgInf.Name}}</h1>

<div class="container">
    
        <table class="table">
            <thead>
                <tr>
                    <th *ngIf="imgInf.render;">Render</th>
                    <th *ngIf="imgInf.Photo[0];">Fotografia</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><img class="render" src="{{imgInf.render}}" *ngIf="imgInf.render;"></td>
                    <td><img class="photo" src="{{imgInf.Photo[0]}}"  *ngIf="imgInf.Photo[0];" ></td>
                
                </tr>
            </tbody>
        </table>
    
</div>
<!--
<div class="container">
    <ul class="slider" >
  
    <img class="render" src="{{imgInf.render}}" *ngIf="imgInf.render;">
    <img class="photo" src="{{imgInf.Photo[0]}}"  *ngIf="imgInf.Photo[0];" >
    </ul>
</div>
-->

<br>
<h2 class="mt-3" *ngIf="imgInf.Autor;">Artista: {{imgInf.Autor}}</h2>

