import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { FastCompanyInfo } from 'src/app/core/models/common.model';
import { Model } from 'src/app/core/models';
import { environment } from 'src/environments/environment';
import { apiKeys, apiUrl } from 'src/app/core/data/appSettings.data';

@Injectable({
  providedIn: 'root',
})
export class ModelService {
  // baseUrl = environment.apiURL;
  private readonly NewBaseUrl = apiUrl;
  private readonly keys = apiKeys;

  private httpOptions = {
    headers: new HttpHeaders({
      'x-api-key': environment.keys,
    })
  };

  constructor(private http: HttpClient) {}

  private setHttpOptionsWithAuthAndCompany(): void {
    const currentCompany = sessionStorage.getItem('currentCompany');
    const Uid = currentCompany ? JSON.parse(currentCompany).Uid : '';

    this.httpOptions.headers = this.httpOptions.headers.set('AuthorizationV2', `Bearer ${sessionStorage.getItem('idToken')}`);
    this.httpOptions.headers = this.httpOptions.headers.set('currentCompany', `${Uid}`);
  }

  create3DModel(model: Model): Observable<any> {
    this.setHttpOptionsWithAuthAndCompany();
    return this.http.post(this.NewBaseUrl + 'model/create', model, this.httpOptions);
  }

  updateModel(model: Model): Observable<any> {
    this.setHttpOptionsWithAuthAndCompany();
    return this.http.put(this.NewBaseUrl + 'model/update', model, this.httpOptions);
  }

  getModels(company: FastCompanyInfo, page?: number): Observable<any> {
    return this.http.get(
      this.NewBaseUrl +
        `model/read?companyId=${company.Uid}&companyName=${company.Name}&page=${page}`, this.httpOptions
    );
  }

  getAllModels(page: number): Observable<any> {
    return this.http.get(this.NewBaseUrl + `model/read/all?page=${page}`, this.httpOptions);
  }

  getModelById(Uid: string): Observable<any> {
    return this.http.get(this.NewBaseUrl + `model/read/id?modelId=${Uid}`, this.httpOptions);
  }
}
