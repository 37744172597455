import { Observable } from 'rxjs';

export class FileItem {
    public name: string;
    public uploading: boolean = false;
    public downloadURL?: Observable<string>; 
    public uploadPercent?: Observable<number | undefined>;
    
    constructor(public file: File = file) {
        this.name = this.normalizeName(file.name);
    }

    normalizeName(str: string) {
        return str.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") //Eliminar tildes
            .replace(/\s+/g, '-') //Reemplazar espacios
    }
}