import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from 'firebase/auth';

import {
  defaultCompany,
  defaultRole,
} from 'src/app/core/data/appSettings.data';
import { Company } from 'src/app/core/models/company.model';
import { User } from 'src/app/core/models/user.model';
import { LoginService } from 'src/app/core/services/login/login.service';
import { AlertComponent } from 'src/app/popups/Alert-message/alert.component';
import { CheckCompanyComponent } from 'src/app/popups/check-company/check-company.component';
import { UserService } from 'src/app/core/services/user/user.service';
import { CompanyService } from './company/company.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUser!: User;
  public currentCompany!: Company;
  public client_id: string = ''; //pruebas

  constructor(
    public afAuth: AngularFireAuth,
    private loginService: LoginService,
    private userService: UserService,
    public dialog: MatDialog,
    private companyService: CompanyService
  ) {

    var fechaActual = new Date();
    console.info(new Date(fechaActual.getTime()).toLocaleString());
    this.userAuthState();
  }
  private userAuthState() {
    this.afAuth.authState.subscribe(
      async (user: any) => {
        if (user) {
          try {
            await this.setUser(user.uid);
            sessionStorage.setItem('user', JSON.stringify(this.currentUser));
            this.updateLastConection(
              this.currentUser,
              user.metadata.lastSignInTime as string
            );
          } catch (e) {
            console.log(
              'Something went wrong getting the user in firestore (authState)',
              e
            );
          }
        } else {
          console.log('AUTHSTATE USER EMPTY', user);
        }
      },
      (err: any) => {
        console.log(
          `${err.status} ${err.statusText} (${err.error.message})`,
          'Please try again'
        );
      }
    );
  }

  async updateLastConection(user: User, lastConection: string) {
    try {
      const newUser: User = {
        Uid: user.Uid,
        Email: user.Email,
        RegisterDate: new Date(
          user.RegisterDate._seconds * 1000
        ).toLocaleString('en-US', { timeZone: 'GMT' }),
        LastConnection: new Date(lastConection).toLocaleString('en-US', {
          timeZone: 'GMT',
        }),
        Name: user.Name,
        DocType: user.DocType,
        Document: user.Document,
        Cellphone: user.Cellphone,
        Gender: user.Gender,
        Birthdate: user.Birthdate
          ? new Date(user.Birthdate._seconds * 1000).toLocaleString('en-US', {
            timeZone: 'GMT',
          })
          : '',
        Description: user.Description,
        Photo: user.Photo,
        Location: user.Location,
        Profession: user.Profession,
        IsActive: user.IsActive,
        Auth: user.Auth,
        BelongsTo: user.BelongsTo,
        Interests: user.Interests,
      };
      const res = await this.userService.updateUser(newUser).toPromise();
    } catch (e: any) {
      console.log('Something went wrong in updating user: ', e);
    }
  }

  async loginGoogle() {
    try {
      const provider = new GoogleAuthProvider();
      const result = await this.afAuth.signInWithPopup(provider);

      // Se llama al servicio de login para obtener el usuario por su id
      this.loginService
        .getUserById(result.user?.uid as string)
        .toPromise()
        .then(async (r) => {
          console.log(r);
          //Save user in sessionStorage
          sessionStorage.setItem(
            'currentUser',
            JSON.stringify(r)
          );

          const credentials = await result.user?.getIdToken()

          if (credentials) {
            sessionStorage.setItem('idToken', credentials);
          }

          // Se verifica si el usuario tiene autenticación con Google
          const found = r.Auth.find((element: string) => element === 'Google');
          if (found) this.checkCompany();
          else {
            // Se agrega el proveedor Google como método de autenticación
            r.Auth.push('Google');
            try {
              // Se actualiza el usuario con el proveedor Google
              const newUser: User = {
                Uid: r.Uid,
                Email: r.Email,
                RegisterDate: new Date(
                  r.RegisterDate._seconds * 1000
                ).toLocaleString('en-US', { timeZone: 'GMT' }),
                LastConnection: new Date(
                  r.LastConnection._seconds * 1000
                ).toLocaleString('en-US', { timeZone: 'GMT' }),
                Name: r.Name,
                DocType: r.DocType,
                Document: r.Document,
                Cellphone: r.Cellphone,
                Gender: r.Gender,
                Birthdate: r.Birthdate
                  ? new Date(r.Birthdate._seconds * 1000).toLocaleString(
                    'en-US',
                    { timeZone: 'GMT' }
                  )
                  : '',
                Description: r.Description,
                Photo: r.Photo,
                Location: r.Location,
                Profession: r.Profession,
                IsActive: r.IsActive,
                Auth: r.Auth,
                BelongsTo: r.BelongsTo,
                Interests: r.Interests,
              };
              const res = await this.userService
                .updateUser(newUser)
                .toPromise();
              console.log(res.msg);
              this.checkCompany();
            } catch (e: any) {
              console.log('Something went wrong in updating user: ', e);
            }
          }
        })
        .catch(async (e) => {
          if (e.error.msg === 'No User found') {
            // Se crea un nuevo usuario con los datos del proveedor Google
            const newUser: User = {
              Uid: result.user?.uid as string,
              Email: result.user?.email as string,
              RegisterDate: new Date(
                result.user?.metadata.creationTime as string
              ),
              LastConnection: new Date(
                result.user?.metadata.lastSignInTime as string
              ),
              Name: result.user?.displayName as string,
              DocType: '',
              Document: '',
              Cellphone: result.user?.phoneNumber || '',
              Gender: '',
              Birthdate: null,
              Description: '',
              Photo: '',
              Location: {
                Country: '',
                State: '',
                City: '',
              },
              Profession: '',
              IsActive: false,
              Auth: ['Google'],
              BelongsTo: [
                {
                  Company: defaultCompany,
                  Roles: [defaultRole],
                },
              ],
              Interests: [],
            };
            try {
              // Se guarda el nuevo usuario en la base de datos
              const res = await this.userService
                .createUser(newUser)
                .toPromise();
              console.log(res);
            } catch (e: any) {
              console.log('Something went wrong in creating user: ', e);
            }
          }
        });
    } catch (e: any) {
      console.log('Something went wrong in loginGoogle: ', e);
    }
  }


  /*
  async loginFacebook() {
    try {
      await this.afAuth
        .signInWithPopup(new firebase.auth.FacebookAuthProvider())
        .then(async (result:any) => {
          console.log(result);

          this.loginService
            .getUserById(result.user?.uid as string)
            .toPromise()
            .then(async (r) => {
              console.log(r);
              const found = r.Auth.find(
                (element: string) => element === 'Facebook'
              ); //verifica si el usuario tiene autenticación con Facebook
              if (found) this.checkCompany();
              else {
                r.Auth.push('Facebook'); //agrega proveedor google en caso de no tenerlo como método de autenticación
                try {
                  const newUser: User = {
                    Uid: r.Uid,
                    Email: r.Email,
                    RegisterDate: new Date(
                      r.RegisterDate._seconds * 1000
                    ).toLocaleString('en-US', { timeZone: 'GMT' }),
                    LastConnection: new Date(
                      r.LastConnection._seconds * 1000
                    ).toLocaleString('en-US', { timeZone: 'GMT' }),
                    Name: r.Name,
                    DocType: r.DocType,
                    Document: r.Document,
                    Cellphone: r.Cellphone,
                    Gender: r.Gender,
                    Birthdate: r.Birthdate
                      ? new Date(r.Birthdate._seconds * 1000).toLocaleString(
                          'en-US',
                          { timeZone: 'GMT' }
                        )
                      : '',
                    Description: r.Description,
                    Photo: r.Photo,
                    Location: r.Location,
                    Profession: r.Profession,
                    IsActive: r.IsActive,
                    Auth: r.Auth,
                    BelongsTo: r.BelongsTo,
                    Interests: r.Interests,
                  };
                  const res = await this.userService
                    .updateUser(newUser)
                    .toPromise(); //actualiza usuario con proveedor google
                  console.log(res.msg);
                  this.checkCompany();
                } catch (e: any) {
                  console.log('Something went wrong in updating user: ', e);
                }
              }
            })
            .catch(async (e) => {
              if (e.error.msg === 'No User found') {
                const newUser: User = {
                  Uid: result.user?.uid as string,
                  Email: result.user?.email as string,
                  RegisterDate: new Date(
                    result.user?.metadata.creationTime as string
                  ),
                  LastConnection: new Date(
                    result.user?.metadata.lastSignInTime as string
                  ),
                  Name: result.user?.displayName as string,
                  DocType: '',
                  Document: '',
                  Cellphone: result.user?.phoneNumber || '',
                  Gender: '',
                  Birthdate: null,
                  Description: '',
                  Photo: '',
                  Location: {
                    Country: '',
                    State: '',
                    City: '',
                  },
                  Profession: '',
                  IsActive: false,
                  Auth: ['Facebook'],
                  BelongsTo: [
                    {
                      Company: defaultCompany,
                      Roles: [defaultRole],
                    },
                  ],
                  Interests: [],
                };
                // auto set company role when popup
                if (window.opener && window.opener !== window) {
                  const allCompanies = await this.companyService
                    .getCompanies()
                    .toPromise();
                  const companyUid = JSON.parse(window.name).companyUid;
                  const found = allCompanies.find(
                    (e: Company) => e.Uid === companyUid
                  );
                  const defaultCompanyPopup = {
                    Uid: found.Uid,
                    Name: found.Name,
                  };
                  newUser.BelongsTo.push({
                    Company: defaultCompanyPopup,
                    Roles: [defaultRole],
                  });
                } //
                try {
                  const res = await this.userService
                    .createUser(newUser)
                    .toPromise();
                  console.log(res);
                  this.setUser(result.user?.uid as string);
                  sessionStorage.setItem(
                    'user',
                    JSON.stringify(this.currentUser)
                  );
                  this.checkCompany();
                } catch (e) {
                  console.log(
                    'Something went wrong registering the user in firestore',
                    e
                  );
                }
              } else {
                console.log(e);
              }
            });
        });
      //return result
    } catch (error) {
      return console.log(error);
    }
  }*/


  //Login Facebook new version
  async loginFacebook() {
    try {
      const provider = new FacebookAuthProvider();
      const result = await this.afAuth.signInWithPopup(provider);
      console.log("Result Facebook ", result);
      // Se llama al servicio de login para obtener el usuario por su id
      this.loginService
        .getUserById(result.user?.uid as string)
        .toPromise()
        .then(async (r) => {
          console.log(r);
          const found = r.Auth.find(
            (element: string) => element === 'Facebook'
          ); //verifica si el usuario tiene autenticación con Facebook
          if (found) this.checkCompany();
          else {
            r.Auth.push('Facebook'); //agrega proveedor google en caso de no tenerlo como método de autenticación
            try {
              const newUser: User = {
                Uid: r.Uid,
                Email: r.Email,
                RegisterDate: new Date(
                  r.RegisterDate._seconds * 1000
                ).toLocaleString('en-US', { timeZone: 'GMT' }),
                LastConnection: new Date(
                  r.LastConnection._seconds * 1000
                ).toLocaleString('en-US', { timeZone: 'GMT' }),
                Name: r.Name,
                DocType: r.DocType,
                Document: r.Document,
                Cellphone: r.Cellphone,
                Gender: r.Gender,
                Birthdate: r.Birthdate
                  ? new Date(r.Birthdate._seconds * 1000).toLocaleString(
                    'en-US',
                    { timeZone: 'GMT' }
                  )
                  : '',
                Description: r.Description,
                Photo: r.Photo,
                Location: r.Location,
                Profession: r.Profession,
                IsActive: r.IsActive,
                Auth: r.Auth,
                BelongsTo: r.BelongsTo,
                Interests: r.Interests,
              };
              const res = await this.userService
                .updateUser(newUser)
                .toPromise(); //actualiza usuario con proveedor google
              console.log(res.msg);
              this.checkCompany();
            } catch (e: any) {
              console.log('Something went wrong in updating user: ', e);
            }
          }
        })
        .catch(async (e) => {
          if (e.error.msg === 'No User found') {
            const newUser: User = {
              Uid: result.user?.uid as string,
              Email: result.user?.email as string,
              RegisterDate: new Date(
                result.user?.metadata.creationTime as string
              ),
              LastConnection: new Date(
                result.user?.metadata.lastSignInTime as string
              ),
              Name: result.user?.displayName as string,
              DocType: '',
              Document: '',
              Cellphone: result.user?.phoneNumber || '',
              Gender: '',
              Birthdate: null,
              Description: '',
              Photo: '',
              Location: {
                Country: '',
                State: '',
                City: '',
              },
              Profession: '',
              IsActive: false,
              Auth: ['Facebook'],
              BelongsTo: [
                {
                  Company: defaultCompany,
                  Roles: [defaultRole],
                },
              ],
              Interests: [],
            };
            // auto set company role when popup
            if (window.opener && window.opener !== window) {
              const allCompanies = await this.companyService
                .getCompanies()
                .toPromise();
              const companyUid = JSON.parse(window.name).companyUid;
              const found = allCompanies.find(
                (e: Company) => e.Uid === companyUid
              );
              const defaultCompanyPopup = {
                Uid: found.Uid,
                Name: found.Name,
              };
              newUser.BelongsTo.push({
                Company: defaultCompanyPopup,
                Roles: [defaultRole],
              });
            } //
            try {
              const res = await this.userService
                .createUser(newUser)
                .toPromise();
              console.log(res);
              this.setUser(result.user?.uid as string);
              sessionStorage.setItem(
                'user',
                JSON.stringify(this.currentUser)
              );
              this.checkCompany();
            } catch (e) {
              console.log(
                'Something went wrong registering the user in firestore',
                e
              );
            }
          } else {
            console.log(e);
          }
        });
      //return result
    } catch (error) {
      return console.log(error);
    }
  }


  async resetPassword(email: string): Promise<void> {
    try {
      return await this.afAuth.sendPasswordResetEmail(email);
    } catch (error) {
      console.log(error);
    }
  }

  async sendVerificationEmail(): Promise<void> {
    return await (await this.afAuth.currentUser)?.sendEmailVerification();
  }


  // Se usa en todos?
  async login(email: string, password: string) {
    try {
      const result = await this.afAuth.signInWithEmailAndPassword(
        email,
        password
      );

      const credentials = await result.user?.getIdToken()

      if (credentials) {
        sessionStorage.setItem('idToken', credentials);
      }
      
      return result;
    } catch (error: any) {
      let messageText: string = '';
      switch (error.code) {
        case 'auth/too-many-requests':
          messageText =
            'El acceso a esta cuenta se ha desactivado temporalmente debido a muchos intentos fallidos de inicio de sesión. Puede restablecerlo inmediatamente restableciendo su contraseña o puede volver a intentarlo más tarde.';
          break;
        case 'auth/user-not-found':
          messageText = 'usuario y/o contraseña son incorrectos.';
          break;
        case 'auth/wrong-password':
          messageText = 'usuario y/o contraseña son incorrectos.';
          break;
      }

      this.dialog.open(AlertComponent, {
        data: {
          message: 'Ups los sentimos ... ',
          parafo: messageText,
          link: '',
          message_button: '',
          button: false,
        },
      });
      return console.log('error login', error);
    }
  }

  async register(email: string, password: string) {
    try {
      const result = await this.afAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      this.sendVerificationEmail();
      return result;
    } catch (error) {
      return error;
    }
  }

  async logout() {
    try {
      await this.afAuth.signOut();
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('CurrentCompany');
    } catch (error) {
      console.log(error);
    }
  }

  checkCompany() {
    this.dialog.open(CheckCompanyComponent);
  }

  async setUser(uid: string) {
    this.currentUser = await this.loginService.getUserById(uid).toPromise();
  }
}
