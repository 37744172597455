import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from 'src/app/core/models/company.model';
import { environment } from 'src/environments/environment';
import { apiKeys, apiUrl } from '../../data/appSettings.data';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private readonly NewBaseUrl = apiUrl;
  private readonly keys = apiKeys;

  private httpOptions = {
    headers: new HttpHeaders({
      'x-api-key': environment.keys,
    })
  };

  constructor(private http: HttpClient) {}

  private setHttpOptionsWithAuthAndCompany(): void {
    const currentCompany = sessionStorage.getItem('currentCompany');
    const Uid = currentCompany ? JSON.parse(currentCompany).Uid : '';

    this.httpOptions.headers = this.httpOptions.headers.set('AuthorizationV2', `Bearer ${sessionStorage.getItem('idToken')}`);
    this.httpOptions.headers = this.httpOptions.headers.set('currentCompany', `${Uid}`);
  }

  createCompany(company: Company): Observable<any> {
    this.setHttpOptionsWithAuthAndCompany();
    return this.http.post(this.NewBaseUrl + 'company/create', company, this.httpOptions);
  }

  updateCompanies(company: Company): Observable<any> {
    this.setHttpOptionsWithAuthAndCompany();
    return this.http.put(this.NewBaseUrl + 'company/update', company, this.httpOptions);
  }

  getCompanies(): Observable<any> {
    return this.http.get(this.NewBaseUrl + 'company/read', this.httpOptions);
  }

  delete(company: Company): Observable<any> {
    this.setHttpOptionsWithAuthAndCompany();
    return this.http.delete(this.NewBaseUrl + `company/delete?uid=${company.Uid}`, this.httpOptions);
  }

  // getCompanyById(company: Company): Observable<any> {
  //   return this.http.get(this.NewBaseUrl + `/${company.Uid}`, this.httpOptions);
  // }
}
