import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//import { AngularFireModule } from '@angular/fire';
import { AngularFireModule } from "@angular/fire/compat";
//import { AngularFireAuthModule, PERSISTENCE } from '@angular/fire/auth';
import { ScreenTrackingService, AngularFireAnalyticsModule }
  from '@angular/fire/compat/analytics';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';


import { AngularFireStorageModule } from '@angular/fire/compat/storage';

import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

//Material
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CheckCompanyComponent } from './popups/check-company/check-company.component';
import { MatListModule } from '@angular/material/list';
import { SnackbarComponent } from './shared/snackbar/snackbar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AlertComponent } from './popups/Alert-message/alert.component';
import { ReadimgComponent } from './popups/readimg/readimg.component';
import { ViewerSkeComponent } from './popups/viewer-ske/viewer-ske.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule, Routes } from '@angular/router';
import { ApiComponent } from './core/services/api/api.component';


import { DatePipe } from '@angular/common';
import { HttpInterceptorService } from './core/services/HttpInterceptorService.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    CheckCompanyComponent,
    SnackbarComponent,
    AlertComponent,
    ReadimgComponent,
    ViewerSkeComponent,
    ApiComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    //AngularFireAuthModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // Material
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatDialogModule,
    MatListModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),



  ],
  providers: [
    /*{
      provide: PERSISTENCE, useValue: 'session'
    },*/
    ScreenTrackingService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
    
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
