import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public menu: any;
  public appar: any;
  public apparAlfa: any;
  public trends: any;
  public Analytics: any;
  public visualizador: any;
  public Digital_Kiosk: any;
  public Real_Time: any;
  public Visualizador_3D: any;
  public VisualizadoUniversa: any;
  public midjourny: any;
  public pdfView: any;
  public inspired:any
  constructor() {
    if (environment.production) { //environment.production
      // Lógica específica para el entorno de producción
      console.log('Estás en modo producción');
      this.menu = {
        title: 'General',
        options: [
          { title: 'Usuarios', value: 'users', Uid: 'rrJYOZGJvpLG23HzZUyj' },
          { title: 'Roles', value: 'roles', Uid: 'QE2xkYBKcsxHfLK9ZTou' },
          { title: 'Compañías', value: 'companies', Uid: 'lYP4xo3mESbu3iJZzGM1'},
          { title: 'Devs', value: 'devs', Uid: 'RSB30q0TpozCpPeedQWA' },
          { title: 'Catálogo', value: 'catalog', Uid: 'UZ0vbB3VvHO4mERdZuKP' },
          { title: 'Modelos', value: 'model', Uid: '3yT45GTlojgQoUz9HPTB' },
          { title: 'Texturas', value: 'textures', Uid: 'mdGk3d7BrgDT7wGH8nXb' },
          { title: 'Escenas', value: 'scenes', Uid: 'oAasFWw5lSWs6BipZKry' },
          { title: 'Renders', value: 'render', Uid: '24XUfQp66BNFy9tLqYfm' },
          { title: 'Métricas', value: 'Métricas', Uid: 'MwfmKKNtZfAiW0JDyWbN' },
          { title: 'Tags-Formatos', value: 'Tags-Format', Uid: 'o63oz3n0ETjT4VZBBUTh'},
          { title: 'Main models', value: 'main-models', Uid: '4IVyvnGekg9p3mPFT9VL'},
          { title: 'Características', value: 'caracteristicas', Uid: 'iZ0CZTW3iguTQzXGDo2l'},
        ],
        selected: 'Usuarios',
      };

      this.appar = {
        title: 'Ar',
        options: [
          { title: 'APP AR', value: 'home', Uid: 'L0FCJmkCCQQEwrXBAqWd' },
          { title: 'Tendencias', value: 'trends', Uid: 'bIGJpP9c1AqwTFPH8Lgo' },
          {
            title: 'Analiticas',
            value: 'analytics',
            Uid: 'beH3f7TvYJ4nrJ4obQAc',
          },
        ],
        selected: 'Usuarios',
      };

      this.apparAlfa = {
        title: 'Ar',
        options: [
          { title: 'Home', value: 'home', Uid: 'L0FCJmkCCQQEwrXBAqWd' },
        ],
        selected: 'Usuarios',
      };

      this.trends = {
        title: 'trends',
        options: [
          { title: 'Tendencias', value: 'home', Uid: 'XYLZpXhhmkcFTIaXISu2' },
          {
            title: 'Preguntas',
            value: 'preguntas',
            Uid: 'TOrVrniLWsrBmuCauTWY',
          },
          {
            title: 'Analiticas',
            value: 'analytics',
            Uid: 'Ce6ZUEOZMR7Het8g2tZo',
          }, ///ojo analiticas GONmtStQuS4wtxDWAXoG
          {
            title: 'Ajustes',
            value: 'GONmtStQuS4wtxDWAXoG',
            Uid: 'GONmtStQuS4wtxDWAXoG',
          },
        ],
        selected: 'Usuarios',
      };

      this.Analytics = {
        title: 'analytics',
        options: [
          {
            title: 'Vitrinas Itinerantes',
            value: 'VitrinasItinerantes',
            Uid: 'df0wz6HnbKxRpbFiIqnr',
          }, //Vitrinas Itinerantes
          // { title: 'Visualizador 3D', value: 'v3D', Uid: 'HxFln' },
        ],
        selected: 'Usuarios',
      };

      this.visualizador = {
        title: 'analytics',
        options: [
          {
            title: 'Visualizador 3D',
            value: 'analytics',
            Uid: 'U62lVA8ofj8pwgyJS1zc',
          },
          {
            title: 'Performance',
            value: 'performance',
            Uid: 't1J3wA1KHV4r1GzVYZ9b',
          },
        ],

        selected: 'Usuarios',
      };
      this.Digital_Kiosk = {
        title: 'Digital Kiosk',
        options: [
          { title: 'Kiosk', value: 'banner', Uid: 'GPNFFjm17Kju1ikw9V2v' },
          {
            title: 'Screen Saver',
            value: 'Screen_Saver',
            Uid: '1e5y26Y7G4kvGwkfOLZw',
          }, //modificar uid
          { title: 'Footer', value: 'footer', Uid: 'b1nQZQNpzNorIgpnmk0w' },
          {
            title: 'Analiticas',
            value: 'analytics',
            Uid: 'fd1heIAaQhofBTs27wRs',
          },
        ],

        selected: 'Usuarios',
      };

      this.Real_Time = {
        title: 'Diseño Real Time - VR',
        options: [
          {
            title: 'Analiticas',
            value: 'analytics',
            Uid: 't7tmUZHv11m3gxR8INZU',
          },
        ],

        selected: 'Usuarios',
      };
      this.Visualizador_3D = {
        title: 'Diseño Real Time - VR',
        options: [
          { title: 'Home', value: 'Home', Uid: 'L5D3f4oEhgiw4yClU2uI' },
          {
            title: 'Analiticas',
            value: 'analytics',
            Uid: 'QtpPqQdIwTtIe79Pf4Ta',
          },
        ],
        selected: 'Usuarios',
      };

      this.VisualizadoUniversa = {
        title: 'Visualizador Universal',
        options: [
          { title: 'Home', value: 'Home', Uid: 'O6PlZZYeFsp2FYmRplAF' },
          {
            title: 'Analiticas',
            value: 'analytics',
            Uid: 'HehAZNfXHIcKQaPUUINP',
          },
        ],
        selected: 'Usuarios',
      };

      this.midjourny = {
        title: 'midjourny',
        options: [
          { title: 'Home', value: 'Home', Uid: 'WYhkzrkvNkw0VY2b9V2c' },
        ],
        selected: 'Usuarios',
      };

      this.pdfView = {
        title: 'pdf view',
        options: [
          { title: 'Mis PDF', value: 'Home', Uid: 'X2uPjqyVNkPqnfpAffRP' },
          {
            title: 'Analiticas',
            value: 'analytics',
            Uid: 'Cajydg1EbodjYhMB5h8e',
          },
        ],
        selected: 'Usuarios',
      };

      this.inspired = {
        title: 'inspírate',
        options: [
          { title: 'Home', value: 'Home', Uid: 'prxFHOWOTS1JhuY8PR0G' },

        ],
        selected: 'Usuarios',
      }

    } else {
      // Lógica específica para el entorno local
      //console.log('Estás en modo local Testing');
      this.menu = {
        title: 'General',
        options: [
          { title: 'Usuarios', value: 'users', Uid: 'rrJYOZGJvpLG23HzZUyj' },
          { title: 'Roles', value: 'roles', Uid: 'QE2xkYBKcsxHfLK9ZTou' },
          { title: 'Compañías', value: 'companies', Uid: 'lYP4xo3mESbu3iJZzGM1'},
          { title: 'Devs', value: 'devs', Uid: 'RSB30q0TpozCpPeedQWA' },
          { title: 'Catálogo', value: 'catalog', Uid: 'UZ0vbB3VvHO4mERdZuKP' },
          { title: 'Modelos', value: 'model', Uid: '3yT45GTlojgQoUz9HPTB' },
          { title: 'Texturas', value: 'textures', Uid: 'mdGk3d7BrgDT7wGH8nXb' },
          { title: 'Escenas', value: 'scenes', Uid: 'oAasFWw5lSWs6BipZKry' },
          { title: 'Renders', value: 'render', Uid: '24XUfQp66BNFy9tLqYfm' },
          { title: 'Métricas', value: 'Métricas', Uid: 'MwfmKKNtZfAiW0JDyWbN' },
          { title: 'Tags-Formatos', value: 'Tags-Format', Uid: 'o63oz3n0ETjT4VZBBUTh',},
          { title: 'Main models', value: 'main-models', Uid: 'X6pTqb6mnq2cwYoEY58k'},
          { title: 'Características', value: 'caracteristicas', Uid: 'r82tCbKgyHPnpPsojmie'},
        ],
        selected: 'Usuarios',
      };

      this.appar = {
        title: 'Ar',
        options: [
          { title: 'APP AR', value: 'home', Uid: 'yl2JXR531B4h4q5rhtzT' },
          { title: 'Tendencias', value: 'trends', Uid: 'sotX3omkNzisD7CYTyRr' },
          {
            title: 'Analiticas',
            value: 'analytics',
            Uid: 'v84kVMkJsWP9qPKORxV2',
          },
        ],
        selected: 'Usuarios',
      };

      this.apparAlfa = {
        title: 'Ar',
        options: [
          {
            title: 'Analiticas',
            value: 'analytics',
            Uid: 'v84kVMkJsWP9qPKORxV2',
          },
        ],
        selected: 'Usuarios',
      };

      this.trends = {
        title: 'trends',
        options: [
          { title: 'Tendencias', value: 'home', Uid: 'zMYRMrLKRnppNGhqD8UL' },
          {
            title: 'Preguntas',
            value: 'preguntas',
            Uid: 'UISouR5Jl987Tlc5cPSc',
          },
          {
            title: 'Analiticas',
            value: 'analytics',
            Uid: 'jwE5ImBFj5n9ovPTH1DL',
          },
          {
            title: 'Ajustes',
            value: 'jNpzTNIzauPTXAovjtE2',
            Uid: 'jNpzTNIzauPTXAovjtE2',
          },
        ],
        selected: 'Usuarios',
      };

      this.Analytics = {
        title: 'analytics',
        options: [
          {
            title: 'Sesiones',
            value: 'VitrinasItinerantes',
            Uid: 'HxFlsdLo12WbHUwDN0Yn',
          },
        ],

        selected: 'Usuarios',
      };

      this.visualizador = {
        title: 'analytics',
        options: [
          {
            title: 'Visualizador 3D',
            value: 'analytics',
            Uid: 'nrCiOwQBfIC4qlielAco',
          },
          {
            title: 'Performance',
            value: 'performance',
            Uid: 'Hc2AjVixB1fUOQaPb2jB',
          },
        ],

        selected: 'Usuarios',
      };

      this.Digital_Kiosk = {
        title: 'Digital Kiosk',
        options: [
          { title: 'Kiosk', value: 'banner', Uid: 'DeeHByHXMqwByD6KhiaV' },
          {
            title: 'Screen Saver',
            value: 'Screen_Saver',
            Uid: 'uxLrD1IjqMmMck4ZZbCQ',
          },
          { title: 'Footer', value: 'footer', Uid: 'W0UIp5r0or93bdJmaczR' },
          {
            title: 'Analiticas',
            value: 'analytics',
            Uid: 'CAmQq6xLi1pttOlEGqzf',
          },
        ],

        selected: 'Usuarios',
      };

      this.Real_Time = {
        title: 'Diseño Real Time - VR',
        options: [
          {
            title: 'Analiticas',
            value: 'analytics',
            Uid: 'EoqMz1LoRG4m85I1ZFsf',
          },
        ],

        selected: 'Usuarios',
      };

      this.Visualizador_3D = {
        title: 'Diseño Real Time - VR',
        options: [
          { title: 'Home', value: 'Home', Uid: 'BiIH8q61L1f8aC0AdEpg' },
          {
            title: 'Analiticas',
            value: 'analytics',
            Uid: 'aNOD1f2d1oOsJBcfdr8G',
          },
        ],
        selected: 'Usuarios',
      };

      this.VisualizadoUniversa = {
        title: 'Visualizador Universal',
        options: [
          { title: 'Home', value: 'Home', Uid: 'Ng2nwlEJfhgVutqK1aN6' },
          {
            title: 'Analiticas',
            value: 'analytics',
            Uid: 'GgrrpK6EoSxi3BUFImt7',
          },
        ],
        selected: 'Usuarios',
      };

      this.midjourny = {
        title: 'midjourny',
        options: [
          { title: 'Home', value: 'Home', Uid: 'WYhkzrkvNkw0VY2b9V2c' },
        ],
        selected: 'Usuarios',
      };

      this.pdfView = {
        title: 'pdf view',
        options: [
          { title: 'Mis PDF', value: 'Home', Uid: 'mor6ms1qBM15KTqNZFbF' },
          {
            title: 'Analiticas',
            value: 'analytics',
            Uid: 'tmhX3GDcdHxl3yDO4JhS',
          },
        ],
        selected: 'Usuarios',
      };
      //QoPIbVqbXgujSDwdj7Ee

      this.inspired = {
        title: 'inspírate',
        options: [
          { title: 'Home', value: 'Home', Uid: 'BhL8LnIvsQxr9sYm9vRR' },

        ],
        selected: 'Usuarios',
      }
    }
  }
}

//Testing
/*
  public menu: any = {
    title: 'General',
    //title: Nombre enlace, value:Path navegación, Uid: Desarrollo Back
    options: [
      { title: 'Usuarios', value: 'users', Uid: 'rrJYOZGJvpLG23HzZUyj' },
      { title: 'Roles', value: 'roles', Uid: 'QE2xkYBKcsxHfLK9ZTou' },
      { title: 'Compañías', value: 'companies', Uid: 'lYP4xo3mESbu3iJZzGM1' },
      { title: 'Devs', value: 'devs', Uid: 'RSB30q0TpozCpPeedQWA' },
      { title: 'Catálogo', value: 'catalog', Uid: 'UZ0vbB3VvHO4mERdZuKP' },
      { title: 'Modelos', value: 'model', Uid: '3yT45GTlojgQoUz9HPTB' },
      { title: 'Texturas', value: 'textures', Uid: 'mdGk3d7BrgDT7wGH8nXb' },
      { title: 'Escenas', value: 'scenes', Uid: 'oAasFWw5lSWs6BipZKry' },
      { title: 'Renders', value: 'render', Uid: '24XUfQp66BNFy9tLqYfm' },
      { title: 'Métricas', value: 'Métricas', Uid: 'MwfmKKNtZfAiW0JDyWbN'},
      { title: 'Tags-Formatos', value: 'Tags-Format', Uid: 'o63oz3n0ETjT4VZBBUTh'},
      { title: 'Main models', value: 'main-models', Uid: 'X6pTqb6mnq2cwYoEY58k'},
    ],
    selected: 'Usuarios',
  };

  public appar: any = {
    title: 'Ar',
    options: [
      { title: 'APP AR', value: 'home', Uid: 'yl2JXR531B4h4q5rhtzT' },
      { title: 'Tendencias', value: 'trends', Uid: 'sotX3omkNzisD7CYTyRr' },
      { title: 'Analiticas', value: 'analytics', Uid: 'v84kVMkJsWP9qPKORxV2' },
    ],
    selected: 'Usuarios',
  };

  public apparAlfa: any = {
    title: 'Ar',
    options: [
      { title: 'Analiticas', value: 'analytics', Uid: 'v84kVMkJsWP9qPKORxV2' },
    ],
    selected: 'Usuarios',
  };

  public trends: any = {
    title: 'trends',
    options: [
      { title: 'Tendencias', value: 'home', Uid: 'zMYRMrLKRnppNGhqD8UL' },
      { title: 'Preguntas', value: 'preguntas', Uid: 'UISouR5Jl987Tlc5cPSc' },
      { title: 'Analiticas', value: 'analytics', Uid: 'jwE5ImBFj5n9ovPTH1DL' },
      {
        title: 'Ajustes',
        value: 'jNpzTNIzauPTXAovjtE2',
        Uid: 'jNpzTNIzauPTXAovjtE2',
      },
    ],
    selected: 'Usuarios',
  };

  public Analytics: any = {
    title: 'analytics',
    options: [
      {
        title: 'Sesiones',
        value: 'VitrinasItinerantes',
        Uid: 'HxFlsdLo12WbHUwDN0Yn',
      },
    ],

    selected: 'Usuarios',
  };

  public visualizador: any = {
    title: 'analytics',
    options: [
      {
        title: 'Visualizador 3D',
        value: 'analytics',
        Uid: 'nrCiOwQBfIC4qlielAco',
      },
      {
        title: 'Performance',
        value: 'performance',
        Uid: 'Hc2AjVixB1fUOQaPb2jB',
      },
    ],

    selected: 'Usuarios',
  };

  public Digital_Kiosk: any = {
    title: 'Digital Kiosk',
    options: [
      { title: 'Kiosk', value: 'banner', Uid: 'DeeHByHXMqwByD6KhiaV' },
      {
        title: 'Screen Saver',
        value: 'Screen_Saver',
        Uid: 'uxLrD1IjqMmMck4ZZbCQ',
      },
      { title: 'Footer', value: 'footer', Uid: 'W0UIp5r0or93bdJmaczR' },
      { title: 'Analiticas', value: 'analytics', Uid: 'CAmQq6xLi1pttOlEGqzf' },
    ],

    selected: 'Usuarios',
  };

  public Real_Time: any = {
    title: 'Diseño Real Time - VR',
    options: [
      { title: 'Analiticas', value: 'analytics', Uid: 'EoqMz1LoRG4m85I1ZFsf' },
    ],

    selected: 'Usuarios',
  };

  public Visualizador_3D: any = {
    title: 'Diseño Real Time - VR',
    options: [
      { title: 'Home', value: 'Home', Uid: 'BiIH8q61L1f8aC0AdEpg' },
      { title: 'Analiticas', value: 'analytics', Uid: 'aNOD1f2d1oOsJBcfdr8G' },
    ],
    selected: 'Usuarios',
  };

  public VisualizadoUniversa: any = {
    title: 'Visualizador Universal',
    options: [
      { title: 'Home', value: 'Home', Uid: 'Ng2nwlEJfhgVutqK1aN6' },
      { title: 'Analiticas', value: 'analytics', Uid: 'GgrrpK6EoSxi3BUFImt7' },
    ],
    selected: 'Usuarios',
  };

  public midjourny: any = {
    title: 'midjourny',
    options: [{ title: 'Home', value: 'Home', Uid: 'WYhkzrkvNkw0VY2b9V2c' }],
    selected: 'Usuarios',
  };

  public pdfView: any = {
    title: 'pdf view',
    options: [
      { title: 'Mis PDF', value: 'Home', Uid: 'mor6ms1qBM15KTqNZFbF' },
      { title: 'Analiticas', value: 'analytics', Uid: 'tmhX3GDcdHxl3yDO4JhS' },
    ],
    selected: 'Usuarios',
  };*/
//QoPIbVqbXgujSDwdj7Ee
//********************************************************************************************************************************* */
//Production
/*
 public menu: any = {
    title: 'General',
    options: [
      { title: 'Usuarios', value: 'users', Uid: 'rrJYOZGJvpLG23HzZUyj' },
      { title: 'Roles', value: 'roles', Uid: 'QE2xkYBKcsxHfLK9ZTou' },
      { title: 'Compañías', value: 'companies', Uid: 'lYP4xo3mESbu3iJZzGM1' },
      { title: 'Devs', value: 'devs', Uid: 'RSB30q0TpozCpPeedQWA' },
      { title: 'Catálogo', value: 'catalog', Uid: 'UZ0vbB3VvHO4mERdZuKP' },
      { title: 'Modelos', value: 'model', Uid: '3yT45GTlojgQoUz9HPTB' },
      { title: 'Texturas', value: 'textures', Uid: 'mdGk3d7BrgDT7wGH8nXb' },
      { title: 'Escenas', value: 'scenes', Uid: 'oAasFWw5lSWs6BipZKry' },
      { title: 'Renders', value: 'render', Uid: '24XUfQp66BNFy9tLqYfm' },
      { title: 'Métricas', value: 'Métricas', Uid: 'MwfmKKNtZfAiW0JDyWbN' },
      { title: 'Tags-Formatos', value: 'Tags-Format', Uid: 'o63oz3n0ETjT4VZBBUTh', },
      //{ title: 'MainModel', value: 'main model', Uid: 'X6pTqb6mnq2cwYoEY58k'},
    ],
    selected: 'Usuarios',
  };

  public appar: any = {
    title: 'Ar',
    options: [
      { title: 'APP AR', value: 'home', Uid: 'L0FCJmkCCQQEwrXBAqWd' },
      { title: 'Tendencias', value: 'trends', Uid: 'bIGJpP9c1AqwTFPH8Lgo' },
      { title: 'Analiticas', value: 'analytics', Uid: 'beH3f7TvYJ4nrJ4obQAc' },
    ],
    selected: 'Usuarios',
  };

  public apparAlfa: any = {
    title: 'Ar',
    options: [
      { title: 'Home', value: 'home', Uid: 'L0FCJmkCCQQEwrXBAqWd' },
    ],
    selected: 'Usuarios',
  };


  public trends: any = {
    title: 'trends',
    options: [
      { title: 'Tendencias', value: 'home', Uid: 'XYLZpXhhmkcFTIaXISu2' },
      { title: 'Preguntas', value: 'preguntas', Uid: 'TOrVrniLWsrBmuCauTWY' },
      { title: 'Analiticas', value: 'analytics', Uid: 'Ce6ZUEOZMR7Het8g2tZo' },///ojo analiticas GONmtStQuS4wtxDWAXoG
      { title: 'Ajustes', value: 'GONmtStQuS4wtxDWAXoG', Uid: 'GONmtStQuS4wtxDWAXoG' },
    ],
    selected: 'Usuarios',
  };

  public Analytics: any = {
    title: 'analytics',
    options: [
      { title: 'Vitrinas Itinerantes', value: 'VitrinasItinerantes', Uid: 'df0wz6HnbKxRpbFiIqnr' },//Vitrinas Itinerantes
     // { title: 'Visualizador 3D', value: 'v3D', Uid: 'HxFln' },

    ],
    selected: 'Usuarios',
  };

   public visualizador: any = {
    title: 'analytics',
    options: [
      { title: 'Visualizador 3D', value: 'analytics', Uid: 'U62lVA8ofj8pwgyJS1zc' },
       { title: 'Performance', value: 'performance', Uid: 't1J3wA1KHV4r1GzVYZ9b' },
    ],


    selected: 'Usuarios',
  };
  public Digital_Kiosk: any = {
    title: 'Digital Kiosk',
    options: [
      { title: 'Kiosk', value: 'banner', Uid: 'GPNFFjm17Kju1ikw9V2v' },
      { title: 'Screen Saver', value: 'Screen_Saver', Uid: '1e5y26Y7G4kvGwkfOLZw' },//modificar uid
      { title: 'Footer', value: 'footer', Uid: 'b1nQZQNpzNorIgpnmk0w' },
      { title: 'Analiticas', value: 'analytics', Uid: 'fd1heIAaQhofBTs27wRs' },
    ],


    selected: 'Usuarios',
  };

  public Real_Time: any = {
    title: 'Diseño Real Time - VR',
    options: [
      { title: 'Analiticas', value: 'analytics', Uid: 't7tmUZHv11m3gxR8INZU' },
    ],


    selected: 'Usuarios',
  };
  public Visualizador_3D: any = {
    title: 'Diseño Real Time - VR',
    options: [
      { title: 'Home', value: 'Home', Uid: 'L5D3f4oEhgiw4yClU2uI' },
      { title: 'Analiticas', value: 'analytics', Uid: 'QtpPqQdIwTtIe79Pf4Ta' },
    ],
    selected: 'Usuarios',
  };

  public VisualizadoUniversa:any  = {
    title: 'Visualizador Universal',
    options: [
      { title: 'Home', value: 'Home', Uid: 'O6PlZZYeFsp2FYmRplAF' },
      { title: 'Analiticas', value: 'analytics', Uid: 'HehAZNfXHIcKQaPUUINP' },
    ],
    selected: 'Usuarios',
  };

  public midjourny:any  = {
    title: 'midjourny',
    options: [
      { title: 'Home', value: 'Home', Uid: 'WYhkzrkvNkw0VY2b9V2c' },
    ],
    selected: 'Usuarios',
  };

  public pdfView:any  = {
    title: 'pdf view',
    options: [
      { title: 'Mis PDF', value: 'Home', Uid: 'X2uPjqyVNkPqnfpAffRP' },
      { title: 'Analiticas', value: 'analytics', Uid: 'Cajydg1EbodjYhMB5h8e' },
    ],
    selected: 'Usuarios',
  };
  */
//********************************************************************************************************************************* */

//constructor() {}
