import { environment } from 'src/environments/environment';
import { FastCompanyInfo } from '../models/common.model';

const { companies, NewapiURL, keys } = environment;


//Production
export const defaultCompany: FastCompanyInfo = {
  Uid: companies.defaultCompany.Uid,
  Name: companies.defaultCompany.Name,
};

export const AlfaCompany: FastCompanyInfo = {
  Uid: companies.AlfaCompany.Uid,
  Name: companies.AlfaCompany.Name,
};

export const tugoCompany: FastCompanyInfo = {
  Uid: companies.tugoCompany.Uid,
  Name: companies.tugoCompany.Name,
};

export const ajusteGenerales: FastCompanyInfo = {
  Uid: companies.ajusteGenerales.Uid,
  Name: companies.ajusteGenerales.Name,
};

export const appArTugo: FastCompanyInfo = {
  Uid: companies.appArTugo.Uid,
  Name: companies.appArTugo.Name,
};

export const testTendecia: FastCompanyInfo = {
  Uid: companies.testTendecia.Uid,
  Name: companies.testTendecia.Name,
};

export const devTest: any = {
  Uid: companies.devTest.Uid,
  Name: companies.devTest.Name,
};

export const defaultRole: any = {
  Uid: companies.defaultRole.Uid,
  Name: companies.defaultRole.Name,
};

export const superAdminRole: any = {
  Uid: companies.superAdminRole.Uid,
  Name: companies.superAdminRole.Name,
};

export const RepresentanteRole: any = {
  Uid: companies.RepresentanteRole.Uid,
  Name: companies.RepresentanteRole.Name,
};

export const adminRole: any = {
  Uid: companies.adminRole.Uid,
  Name: companies.adminRole.Name,
};

export const t3DArtistRole: any = {
  Uid: companies.t3DArtistRole.Uid,
  Name: companies.t3DArtistRole.Name,
};

export const t3DSuperRole: any = {
  Uid: companies.t3DSuperRole.Uid,
  Name: companies.t3DSuperRole.Name,
};

export const VitrinasItinerantes: FastCompanyInfo = {
  Uid: companies.VitrinasItinerantes.Uid,
  Name: companies.VitrinasItinerantes.Name,
};

export const Visualizador3D: any = {
  Uid: companies.Visualizador3D.Uid,
  Name: companies.Visualizador3D.Name,
};

export const Digital_Kiosk: any = {
  Uid: companies.Digital_Kiosk.Uid,
  Name: companies.Digital_Kiosk.Name,
};

export const apiUrl: string = NewapiURL;
export const apiKeys: string = keys;

export const testSettings: any = {
  Uid: companies.testSettings.Uid,
  Name: companies.testSettings.Name,
};

export const RealTime:any = {
  Uid: companies.RealTime.Uid,
  Name: companies.RealTime.Name,
};


export const Visualizado3D:any = {
  Uid: companies.Visualizado3D.Uid,
  Name: companies.Visualizado3D.Name,
};


export const VisualizadorUniversal:any = {
  Uid: companies.VisualizadorUniversal.Uid,
  Name: companies.VisualizadorUniversal.Name,
};


export const midjourny:any = {
  Uid: companies.midjourny.Uid,
  Name: companies.midjourny.Name,
};

export const pdfView:any = {
  Uid: companies.pdfView.Uid,
  Name: companies.pdfView.Name,
};

export const inspired:any = {
  Uid: companies.sectionInspirate.Uid,
  Name: companies.sectionInspirate.Name,
}

export const sectionKios:any = environment.sectionKios


